import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import Footer from "../../components/Footer/Footer.jsx";

import routes from "../../routes.js";
import ModalRoot from "../../dialogs/ModalRoot";
import {getLang, setLink} from "../../Values";
import languages from "../../languages";

class Pages extends React.Component {
  constructor(props){
    super(props);
    const lang = props.match.params.lang;
    if(!lang || !['en', 'fa'].includes(lang)){
      this.lang = 'en';
      props.history.replace(`/en${props.location.pathname}`);
      window.location.reload();

    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/public") {
        return (
          <Route
            path={`/:lang(fa|en)?${prop.path}`}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = routes => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (this.props.location.pathname.includes(routes[i].path))
          return this.lang === 'fa'? routes[i].rtlName: routes[i].name;
      }
    }
    return activeRoute;
  };
  getFullPageName = routes => {
    let pageName = this.getActiveRoute(routes);
    switch (pageName) {
      case "Pricing":
        return "pricing-page";
      case "Login":
        return "login-page";
      case "Register":
        return "register-page";
      case "Lock Screen":
        return "lock-page";
      default:
        return "Default Brand Text";
    }
  };
  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
    document.body.classList.add("white-content");
    this.lang = getLang(this.props);
    if(this.lang === "fa") {
      document.body.classList.add("rtl", "menu-on-right");
      document.documentElement.classList.add("rtl-active");
    } else {
      document.body.classList.remove("rtl", "menu-on-right");
      document.documentElement.classList.remove("rtl-active");
    }
  }
  render() {
    this.lang = getLang(this.props);
    const title = this.getActiveRoute(routes);
    document.title = `${languages[this.lang].yekpay} - ${title}`;
    return (
      <>
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className={"full-page " + this.getFullPageName(routes)}>
            <ModalRoot />
            <Switch>
              {this.getRoutes(routes)}
              <Redirect to={setLink('/not-found', this.lang)}/>
            </Switch>
            {/* <Footer fluid lang={this.lang}/> */}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Pages);
