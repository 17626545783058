import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './reducers/configureStore';

import AuthLayout from "layouts/Auth/Auth.jsx";
import PublicLayout from "layouts/Public/Public.jsx";
import RTLLayout from "layouts/RTL/RTL.jsx";

import "assets/css/nucleo-icons.css";
import "assets/css/all.css";
import "assets/css/placeholder.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "assets/demo/demo.css";
import "react-notification-alert/dist/animate.css";

const hist = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={hist}>
            <Switch>
              <Route path={process.env.PUBLIC_URL + "/:lang(fa|en)?/not-found"} render={props => <PublicLayout {...props} />}/>
              <Route path={process.env.PUBLIC_URL + '/:lang(fa|en)?/:username'} render={props => <PublicLayout {...props} />} exact />
            </Switch>
          </Router>
        </PersistGate>
    </Provider>,
  document.getElementById("root")
);
