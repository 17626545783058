import React from "react";
import languages from "../../languages";
// reactstrap components
import { Row, Col } from "reactstrap";

const Loading = (props) => {
  return (
    <Row>
      <Col>
        <div className="py-5">
          <div className="sk-three-bounce bg-transparent">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Loading;
