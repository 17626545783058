import React from "react";
import _ from "lodash";
import { CircularProgressbar } from 'react-circular-progressbar';

// Component that renders an arbitrary
// number of divs on top of CircularProgressbar,
// whose content is centered.
function LayeredProgressbar(props) {
  const overlayStyles = {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  };
  const overlays = props.renderOverlays();
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%"
      }}
    >
      <div style={{ position: "absolute" }}>
        <svg style={{ height: 0, width: 0 }}>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#6FADA0" />
            <stop offset="100%" stopColor="#91589D" />
          </linearGradient>
        </svg>
        <div className="circle">
          <div className="circle__spin">
            <svg>
              <circle cx="50%" cy="50%" r="40px"></circle>
            </svg>
          </div>
        </div>
        <CircularProgressbar value={props.percentage} textForPercentage={null} strokeWidth={15} styles={{
          root: {},
          path: {
            stroke: "url(#gradient)",
            strokeLinecap: "butt",
            transition: "stroke-dashoffset 0.5s ease 0s"
          },
          trail: {
            stroke: "#d6d6d6"
          },
          text: {
            fill: "#f88",
            fontSize: "30px"
          }
        }} />
      </div>
      {overlays.map((overlay, index) => (
        <div style={overlayStyles} key={index}>
          {overlay}
        </div>
      ))}
    </div>
  );
}

function RadialSeparator(props) {
  return (
    <div
      style={{
        background: "#fff",
        width: "3px",
        height: "100%",
        transform: `rotate(${props.degrees}deg)`
      }}
    />
  );
}

// Expects an even number of separators.
// Can be implemented with non-even numbers
// but requires changing the styling of LayeredProgressbar,
// left as exercise to the reader.
function getRadialSeparators(numSeparators) {
  const degrees = 360 / numSeparators;
  return _.range(numSeparators / 2).map(index => (
    <RadialSeparator degrees={index * degrees} />
  ));
}

function SegmentedProgressbar(props) {
  return (
    <LayeredProgressbar
      percentage={props.percentage}
      styles={{
        path: {
          strokeLinecap: "butt"
        }
      }}
      renderOverlays={() =>
        getRadialSeparators(6).concat(
          <div style={{ fontSize: 25, color: "#91589D" }}>
            %{props.percentage}
          </div>
        )
      }
    />
  );
}

export default SegmentedProgressbar;
