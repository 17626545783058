import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import rootReducer from './index'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['dialogReducer']
};
if(process.env.NODE_ENV==="production"){
    console.log=function(){};
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
