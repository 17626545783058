import React from "react";
import languages from "../../languages";
// reactstrap components
import {
    Row,
    Col
} from "reactstrap";
import {getLang} from "../../Values";

class Error extends React.Component {

    render() {
        this.lang = getLang(this.props);
        return (
            <>
                <Row className="justify-content-center mt-5">
                    <Col lg="8">
                        <div className={'receipt-container-failed'}>
                            <h2>{languages[this.lang].errorNotFound}</h2>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Error;
