import React from "react";
import classnames from "classnames";
import { NavLink, withRouter } from "react-router-dom";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  NavItem,
  Container,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {setLink} from "../../Values";
import languages from "../../languages";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  toggle(e, language) {
    const pathnameArr = this.props.location.pathname.split('/');
    console.log(pathnameArr);
    if(pathnameArr.length > 1){
      const path = this.props.location.pathname.replace(/^\/[a-z]{2}\/*/, "/");
      window.location.assign(setLink(path, language));
    }
    e.preventDefault();
  }

  render() {
    this.lang = this.props.lang;
    return (
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
              {this.props.brandText}
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse isOpen={this.state.collapseOpen} navbar>
            <Nav navbar className="ml-auto  navbar-nav-auto">
              <NavItem>
                <NavLink to={setLink('/auth/register', this.lang)} className="nav-link">
                  <i className="tim-icons icon-laptop" /> {languages[this.lang].register}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={setLink('/auth/login', this.lang)} className="nav-link">
                  <i className="tim-icons icon-single-02" /> {languages[this.lang].login}
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <i className="fal fa-globe mx-2" />
                    <span>{this.lang === 'en'? 'English': 'فارسی'}</span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <a href="#" onClick={(e) => this.toggle(e, 'fa')}>
                        <DropdownItem className="nav-item pointer">
                          فارسی
                        </DropdownItem>
                      </a>
                    </NavLink>
                    <NavLink tag="li">
                      <a href="#" onClick={(e) => this.toggle(e, 'en')}>
                        <DropdownItem className="nav-item pointer">
                          English
                        </DropdownItem>
                      </a>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(AuthNavbar);
